<script>
import ChildSupportCalculations from '../components/ChildSupport/ChildSupportCalculations.vue';

export default {
	components: { ChildSupportCalculations },
};
</script>

<template>
	<div><child-support-calculations /></div>
</template>
