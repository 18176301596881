<template>
    <div>
        <b-container fluid>
            <loader v-if="loading" />
            <div v-else>
                <b-card>
                    <h4 slot="header" class="section-heading">Child Support Calculation Results</h4>
                    <b-table-simple>
                        <b-thead>
                            <tr>
                                <b-th></b-th>
                                <b-th>{{ partner1Name }}</b-th>
                                <b-th>{{ partner2Name }}</b-th>
                            </tr>
                        </b-thead>
                        <tr>
                            <b-th>Province</b-th>
                            <b-td>{{ partner1Province }}</b-td>
                            <b-td>{{ partner2Province }}</b-td>
                        </tr>
                        <tr>
                            <b-th>Income</b-th>
                            <b-td>${{ partner1Income }}</b-td>
                            <b-td>${{ partner2Income }}</b-td>
                        </tr>
                        <tr>
                            <b-th>Child Support</b-th>
                            <b-td>${{ formatted1stChildSupport.childSupportAmount }}</b-td>
                            <b-td>${{ formatted1stChildSupport.childSupportAmount }}</b-td>
                        </tr>
                    </b-table-simple>
                    <br>
                    <p>Child Support Payable: {{ determineWhichParentPays.whoPays }} pays ${{
                            determineWhichParentPays.supportAmount
                    }} to
                        {{ determineWhichParentPays.whoDoesNotPay }} per month.</p>
                </b-card>
                <br>
                <b-card>
                    <h4 slot="header" class="section-heading">Calculation Details</h4>
                    These were calculated with the formula of ( ((Income)-(Minimum Income in Range)) * (Plus %) ) +
                    (BasicAmount) = Child Support Amount
                    <br>
                    Then the lower income partner's child support was subtracted from that amount.<br>
                    E.g. ( ( (${{ formatted1stChildSupport.income }})-(${{ formatted1stChildSupport.minIncome }})) *
                    ({{ formatted1stChildSupport.percentage }}%) ) + (${{ formatted1stChildSupport.baseAmount }}) =
                    ${{ formatted1stChildSupport.childSupportAmount }}<br><br>

                    For an extraordinary expense of $100,000, {{ partner1Name }} will pay
                    ${{ calculatedExtraordinaryExpenses.partner1Share }}, or
                    {{ calculatedExtraordinaryExpenses.partner1Percentage }}%.<br>
                    {{ partner2Name }} will pay ${{ calculatedExtraordinaryExpenses.partner2Share }}, or
                    {{ calculatedExtraordinaryExpenses.partner2Percentage }}%.<br><br>

                    This was calculated with the formula of (Partner 1 Income)/(Total Income of both Partners) * 100 to
                    get
                    the
                    percentage for partner 1 and then that percentage was applied to the extraordinary amount.<br>
                    The same formula was applied for the second partner.<br><br>
                </b-card>
            </div>
        </b-container>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import calculateChildSupport from '@/lib/calculateChildSupport'
import calculateExtraordinaryExpenses from '@/lib/calculateExtraordinaryExpenses'
import determineWhichParentPays from '@/lib/determineWhichParentPays'
import toNumber from 'lodash/toNumber';
import formatCurrency from '@/lib/formatMoney';

import {
    preferredName,
} from '@/lib/agreement/filters';


export default {
    computed: {
        ...mapState(['agreement', 'childSupportTables']),

        numberOfChildren() {
            if (!(this.agreement)) return;
            return this.agreement.children.length;
        },
        partner1() {
            if (!(this.agreement && this.agreement.partner1)) return;
            return this.agreement.partner1;
        },
        partner1Name() { //returns partner 1 name as string
            if (!(this.agreement && this.partner1)) return;
            return `${preferredName(this.partner1)} ${this.partner1.name.last}`;
        },
        partner1Province() {
            if (!(this.agreement && this.agreement.partner1)) return '';
            return this.agreement.partner1.address.province;
        },
        partner1Income() {
            if (!(this.agreement && this.agreement.partner1)) return;
            return formatCurrency(this.agreement.partner1.income);
        },
        calculated1stChildSupport() { //returns partner 1 child support as {childSupportAmount,income,minIncome,percentage,baseAmount}
            if (!(this.agreement && this.partner1)) return;
            return calculateChildSupport(this.partner1, this.numberOfChildren, this.childSupportTables);
        },
        formatted1stChildSupport() { //formats child support into dollar format
            if (!(this.agreement && this.partner1)) return;
            let { childSupportAmount, income, minIncome, baseAmount } = this.calculated1stChildSupport
            childSupportAmount = formatCurrency(childSupportAmount);
            income = formatCurrency(income);
            minIncome = formatCurrency(minIncome);
            baseAmount = formatCurrency(baseAmount);
            const result = { childSupportAmount, income, minIncome, baseAmount }
            return result;
        },
        partner2() {
            if (!(this.agreement && this.agreement.partner2)) return;
            return this.agreement.partner2;
        },
        partner2Name() {
            if (!(this.agreement && this.partner2)) return;
            return `${preferredName(this.partner2)} ${this.partner2.name.last}`;
        },
        partner2Province() {
            if (!(this.agreement && this.agreement.partner2)) return '';
            return this.agreement.partner2.address.province;
        },
        partner2Income() {
            if (!(this.agreement && this.agreement.partner2)) return;
            return formatCurrency(this.agreement.partner2.income);
        },
        calculated2ndChildSupport() { //returns partner 2 child support as {childSupportAmount,income,minIncome,percentage,baseAmount}
            if (!(this.agreement && this.partner2)) return;
            return calculateChildSupport(this.partner2, this.numberOfChildren, this.childSupportTables);
        },
        formatted2ndChildSupport() { //formats child support into dollar format
            if (!(this.agreement && this.partner2)) return;
            let { childSupportAmount, income, minIncome, baseAmount } = this.calculated2ndChildSupport
            childSupportAmount = formatCurrency(childSupportAmount);
            income = formatCurrency(income);
            minIncome = formatCurrency(minIncome);
            baseAmount = formatCurrency(baseAmount);
            const result = { childSupportAmount, income, minIncome, baseAmount }
            return result;
        },
        determineWhichParentPays() { //returns {whoPays,whoDoesNotPay,partner1Support,partner2Support, supportAmount}
            if (!(this.agreement && this.partner1 && this.partner2)) return;
            const partner1Support = toNumber(this.calculated1stChildSupport.childSupportAmount);
            const partner2Support = toNumber(this.calculated2ndChildSupport.childSupportAmount);
            return determineWhichParentPays(this.partner1, partner1Support, this.partner2, partner2Support);
        },
        calculatedExtraordinaryExpenses() { //returns {totalIncome, partner1Percentage, partner1Share, partner2Percentage, partner2Share}
            if (!(this.agreement && this.partner1 && this.partner2)) return;
            const TEST_EXPENSE = 100000; //TODO Either get extraordinary expenses from agreement, or just provide an example of an extraordinary expense
            return calculateExtraordinaryExpenses(this.partner1, this.partner2, TEST_EXPENSE);
        },
    },
    mounted() {
        if (this.loading) {
            this.$store.dispatch('fetchChildSupportTables').then(() => {
                this.loading = false;
            });
        }
    },
    data() {
        return {
            loading: true,
        };
    },
};
</script>
