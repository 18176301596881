<script>
import allProvinces from 'provinces';
import filter from 'lodash/filter';
import formatCurrency from '@/lib/formatMoney';

const provinces = filter(allProvinces, { country: 'CA' });

export default {
	props: {
		value: {
			type: Object,
			default() {
				return {
					province: '',
				};
			},
		},
	},
	computed: {
		partner1IncomeFormatted() {
            return formatCurrency(this.partner1Income);
        }, 
		partner2IncomeFormatted() {
            return formatCurrency(this.partner2Income);
        }, 
	},
	data() {
		return {
			provinces,
			partner1Income: '',
			partner2Income: '',
			partner1Province: '',
			partner2Province: '',
			numberOfChildren: '',
			parentingArrangement: '',
		};
	},
};

</script>

<template>
	<b-container fluid>
		<b-card class="mt-2">
			<h4 slot="header" class="section-heading">Child Support Details</h4>
			<h5>Party 1</h5>
				<input-group
					dollar
					label="Gross Income"
					class="mb-3"
					v-model="partner1Income"
				/>
				<b-row>
				<b-col cols="4">
					<b-form-select
						:options="provinces"
						label="province"
						text-field="name"
						value-field="name"
						v-model="partner1Province"
						placeholder="Province"
					>
						<template #first>
							<b-form-select-option value="" disabled selected>
								Province
							</b-form-select-option>
						</template>
					</b-form-select>
				</b-col>
			</b-row>
			<br>
			<h5>Party 2</h5>
				<input-group
					dollar
					label="Gross Income"
					class="mb-3"
					v-model="partner2Income"
				/>
				<b-row>
				<b-col cols="4" class="input">
					<b-form-select
						:options="provinces"
						text-field="name"
						value-field="name"
						v-model="partner2Province"
					>
						<template #first>
							<b-form-select-option value="" disabled selected>
								Province
							</b-form-select-option>
						</template>
					</b-form-select>
				</b-col>
			</b-row>
			<br>
			<b-row>
				<b-col cols="2" class="input">
					<label for="children">Number of Children</label>
					<b-form-input 
						id="children"
						type="number"
						min="1" 
						max="100" 
						step="1"
						v-model="numberOfChildren"
					>
					</b-form-input>
				</b-col>
			</b-row>
			<br>
			<radio-group
				class="mb-3"
				input-cols="4"
				label="What is the Parenting Arrangement?"
				v-model="parentingArrangement"
				reset-undefined
				select
				:options="[
					{
						value: 'Primary',
						text: 'Primary with Parenting Time for other parent',
					},
					{
						value: 'Primary/Contact',
						text: 'Primary with Contact for other party',
					},
					{ value: 'Shared', text: 'Shared Parenting' },
				]"
				:length="'long'"
			/>
		</b-card>
		<br>
		<b-card>
			<h4 slot="header" class="section-heading">Child Support Calculation Results</h4>
				<b-table-simple>
					<b-thead>
						<b-tr>
							<b-th></b-th>
							<b-th>Party 1</b-th>
							<b-th>Party 2</b-th>
						</b-tr>
					</b-thead>
					<b-tr>
						<b-th>Province</b-th>
						<b-td>{{ partner1Province }}</b-td>
						<b-td>{{ partner2Province }}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Income</b-th>
						<b-td>${{ partner1IncomeFormatted }}</b-td>
						<b-td>${{ partner2IncomeFormatted }}</b-td>
					</b-tr>
					<b-tr>
						<b-th>Child Support</b-th>
						<b-td>$</b-td>
						<b-td>$</b-td>
					</b-tr>
				</b-table-simple>
				<br>
				<p>Number of Children: {{ numberOfChildren }}</p>
				<p>Parenting Arrangement: {{ parentingArrangement }}</p>
				<h5>Child Support Payable: $</h5>
		</b-card>
	</b-container>
</template>
