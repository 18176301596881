import toNumber from 'lodash/toNumber';
import formatCurrency from '@/lib/formatMoney';
import { preferredName } from '@/lib/agreement/filters';

function determineWhichParentPays(
	partner1,
	partner1Support,
	partner2,
	partner2Support
) {
	let whoPays, whoDoesNotPay, supportAmount;
	partner1Support = toNumber(partner1Support);
	partner2Support = toNumber(partner2Support);
	const partner1Name = `${preferredName(partner1)} ${partner1.name.last}`;
	const partner2Name = `${preferredName(partner2)} ${partner2.name.last}`;

	if (partner1Support > partner2Support) {
		whoPays = partner1Name;
		whoDoesNotPay = partner2Name;
		supportAmount = formatCurrency(partner1Support - partner2Support);
	} else if (partner1Support < partner2Support) {
		whoPays = partner2Name;
		whoDoesNotPay = partner1Name;
		supportAmount = formatCurrency(partner2Support - partner1Support);
	} else {
		whoPays = 'equal';
		supportAmount = 0;
	}

	partner1Support = formatCurrency(partner1Support);
	partner2Support = formatCurrency(partner2Support);

	const result = {
		whoPays,
		whoDoesNotPay,
		partner1Support,
		partner2Support,
		supportAmount,
	};

	return result;
}

export default determineWhichParentPays;
