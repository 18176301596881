<script>
import RenderChildSupportCalculations from '../../views/ChildSupportComponent.vue';
import RenderChildSupportStandalone from '../../views/ChildSupportStandalone.vue';

export default {
	components: { RenderChildSupportCalculations, RenderChildSupportStandalone },
	props: ['agreement'],
};
</script>

<template>
	<div>
		<div v-if="agreement">
			<RenderChildSupportCalculations />
		</div>
		<div v-else>
			<RenderChildSupportStandalone />
		</div>
	</div>
</template>
