import formatCurrency from '@/lib/formatMoney';

function calculateExtraordinaryExpenses(partner1, partner2, expenseAmount) {
	let totalIncome = partner1.income + partner2.income;
	let partner1Percentage = partner1.income / totalIncome;
	let partner2Percentage = partner2.income / totalIncome;
	const partner1Share = formatCurrency(expenseAmount * partner1Percentage);
	const partner2Share = formatCurrency(expenseAmount * partner2Percentage);

	partner1Percentage = ((partner1.income / totalIncome) * 100).toFixed(3);
	partner2Percentage = ((partner2.income / totalIncome) * 100).toFixed(3);
	totalIncome = formatCurrency(totalIncome);

	const result = {
		totalIncome,
		partner1Percentage,
		partner1Share,
		partner2Percentage,
		partner2Share,
	};

	return result;
}

export default calculateExtraordinaryExpenses;
