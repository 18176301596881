import toNumber from 'lodash/toNumber';
import toString from 'lodash/toString';

/* 
Purpose: Calculate child support amount from inputs
Parameters: 
    province - province in which the user lives
    income - users reported income last year
    numberOfChildren - number of children user has
Return:
    childSupportAmount - amount that the user needs to pay in child support (based on single parenting model)
*/
function calculateChildSupport(partner, numberOfChildren, childSupportTables) {
	let income = partner.income;
	let province = partner.address.province;

	if (income == 0) {
		income = 1;
	}
	//determine minIncome for purposes of URL and calculation
	if (!(income && province && numberOfChildren))
		return 'Error calculating child support';
	if (income < 12000) {
		return 0;
	}
	let minIncome, childSupportAmount;
	if (income < 150001) {
		//if income is 5 digits, take first 2 digits and multiply by 1000 to get the minimum income for the row we will be using
		if (income.toString().length == 5) {
			minIncome = toNumber(toString(income).slice(0, 2)) * 1000;
		}
		//if income is 6 digits, take first 3 digits and multiply by 1000 to get the minimum income for the row we will be using
		else if (income.toString().length == 6) {
			minIncome = toNumber(toString(income).slice(0, 3)) * 1000;
		}
	} else {
		minIncome = 150000;
	}
	if (numberOfChildren > 6) {
		numberOfChildren = 6;
	}
	province = province.toLowerCase().replace(' ', '_');
	let tableRow =
		childSupportTables[province][`${numberOfChildren}child`][minIncome];
	const { percentage, baseAmount } = tableRow;
	childSupportAmount = (
		(income - minIncome) * (percentage / 100) +
		baseAmount
	).toFixed(2);
	const result = {
		childSupportAmount,
		income,
		minIncome,
		percentage,
		baseAmount,
	};
	return result;
}

export default calculateChildSupport;
